<template>
  <div v-if="image && image[0]" class="relative sm:py-12">
    <div class="sm:hidden">
      <CraftAsset
        v-if="image[0]"
        :asset="image[0]"
        :ratio="0.55"
        :autoplay="true"
        :muted="true"
        :hfull="true"
        :render-covered="true"
        sizes="100vw"
        crop="fill"
      />
    </div>
    <div class="hidden sm:block absolute inset-0 overflow-hidden">
      <CraftAsset
        v-if="image[0]"
        :asset="image[0]"
        :autoplay="true"
        :muted="true"
        :hfull="true"
        :render-covered="true"
        sizes="100vw"
        crop="fill"
      />
    </div>
    <div
      class="sm:container sm:h-full relative flex items-center z-10 sm:min-h-[26rem]"
      :class="{ 'justify-end': boxPositionRight }"
    >
      <div class="p-6 lg:p-12 bg-blackly w-full sm:max-w-lg">
        <ProductProvider
          v-if="course"
          v-slot="{ addToCartDisabled, addToCart, price, compareAtPrice, title, isCourse }"
          :handle="course"
        >
          <div class="fluid-xl leading-tight font-semibold mb-4 text-white">{{ title ?? heading }}</div>
          <div v-if="bodyText" class="text-white mb-4" v-html="bodyText"></div>
          <div v-if="price" class="fluid-xl leading-tight font-medium text-br-green-light mb-6">
            <span
              v-if="compareAtPrice && compareAtPrice.amount > 0"
              class="text-gray-light inline-block line-through decoration-gray-light mr-2 fluid-xl leading-tight self-baseline font-light"
              data-test-id="old-price"
            >
              {{
                $n(compareAtPrice.amount, {
                  style: 'currency',
                  currency: compareAtPrice.currencyCode,
                })
              }}
            </span>
            <span :class="{ 'fluid-lg leading-tight font-medium': compareAtPrice }">{{
              $n(price.amount, {
                style: 'currency',
                currency: price.currencyCode,
              })
            }}</span>
            <em class="text-white fluid-base leading-relaxed font-light not-italic">{{ $t('vatIncl') }}</em>
          </div>
          <button
            :class="{ 'btn-pill-gray pointer-events-none': addToCartDisabled }"
            :disabled="addToCartDisabled"
            class="btn-pill-green whitespace-nowrap mr-1.5"
            data-testid="addToCart"
            @click="hasPreAssessment ? (showPreAssessmentModal = true) : addToCart()"
          >
            {{ isCourse ? $t('cart.bookNow') : $t('cart.addToCart') }}
          </button>
          <portal to="modal">
            <PreAssessmentModal
              v-if="hasPreAssessment && showPreAssessmentModal"
              :showing="showPreAssessmentModal"
              :info="agreementCategory[0]"
              @buy="addToCart"
              @close="showPreAssessmentModal = false"
            />
          </portal>
        </ProductProvider>
        <div v-else>
          <div class="fluid-xl leading-tight font-semibold mb-4 text-white">{{ heading }}</div>
          <div v-if="staticPrice" class="fluid-xl leading-tight font-medium text-br-green-light mb-6">
            <span
              v-if="staticCompareAtPrice"
              class="text-gray-light inline-block line-through decoration-gray-light mr-2 fluid-xl leading-tight self-baseline font-light"
              data-test-id="old-price"
            >
              {{
                $n(staticCompareAtPrice, {
                  style: 'currency',
                  currency: 'EUR',
                })
              }}
            </span>
            <span :class="{ 'fluid-lg leading-tight font-medium': staticCompareAtPrice }">{{
              $n(staticPrice, {
                style: 'currency',
                currency: 'EUR',
              })
            }}</span>
            <em class="text-white fluid-base leading-relaxed font-light not-italic">{{ $t('vatIncl') }}</em>
          </div>
          <LinkField v-if="cta[0]" v-bind="cta[0]" class="btn-pill-green whitespace-nowrap mr-1.5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CraftAsset from '~/components/common/CraftAsset';
import ProductProvider from '~/components/common/ProductProvider';
import LinkField from '@/components/common/LinkField';
import PreAssessmentModal from '~/components/common/blocks/PreAssessmentModal';

export default {
  components: {
    CraftAsset,
    ProductProvider,
    LinkField,
    PreAssessmentModal,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    course: {
      type: String, // This is a Shopify product handle
      default: null,
    },
    bodyText: {
      type: String,
      default: null,
    },
    boxPositionRight: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Array,
      default: () => [],
    },
    cta: {
      type: Array,
      default: () => [],
    },
    staticPrice: {
      type: Number,
      default: null,
    },
    staticCompareAtPrice: {
      type: Number,
      default: null,
    },
    agreementCategory: {
      type: Array,
      default: () => null,
    },
  },

  data() {
    return {
      showPreAssessmentModal: false,
    };
  },

  computed: {
    hasPreAssessment() {
      return this.agreementCategory?.length > 0;
    },
  },
};
</script>
