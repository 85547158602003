<template>
  <div id="productReviews" class="bg-putty py-8 md:py-24">
    <div class="container">
      <div class="flex">
        <div class="w-full md:w-1/2">
          <h2 class="fluid-3xl leading-tightest">{{ $t('product.reviewsHeader') }}</h2>
          <h3
            v-if="productReviews"
            class="fluid-lg leading-tight font-medium mt-2 text-gray-600"
            data-test-id="review-title"
          >
            {{ tsProduct.name }}
          </h3>
        </div>
        <div v-if="productReviews" class="w-1/2 text-right hidden md:block">
          <div class="relative">
            <span
              v-on-click-outside="closePopup"
              data-test-id="toggle-filters"
              class="cursor-pointer inline-block border-b border-black fluid-sm tracking-wider font-medium"
              @click="toggleFilter = !toggleFilter"
              >{{ $t('product.filter') }}</span
            >
            <ul v-if="toggleFilter" class="absolute right-0 p-5 bg-white shadow-lg" data-test-id="filters">
              <li v-for="index in 5" :key="index" class="my-2">
                <div class="flex flex-nowrap w-full cursor-pointer" @click="getReviewsListByStar(6 - index)">
                  <div class="relative w-2/3"><RatingStars :rating-value="6 - index" /></div>
                  <span class="block w-1/3 text-right pl-4 fluid-sm tracking-wider font-medium"
                    >({{ getCountByRating(6 - index) }})</span
                  >
                </div>
              </li>
              <li>
                <div class="flex flex-nowrap cursor-pointer w-full text-left" @click="getReviewsListByStar(false)">
                  <div class="relative w-2/3">{{ $t('product.allStars') }}</div>
                  <span class="block w-1/3 text-right pl-4 fluid-sm tracking-wider font-medium"
                    >({{ totalCount }})</span
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="productReviews" class="flex flex-wrap md:flex-nowrap my-6 md:my-12">
        <div class="w-full md:w-1/2 lg:w-1/3 md:pr-10 lg:pr-0">
          <RatingStars
            v-if="totalScore"
            :rating-value="totalScore"
            :rating-volume="totalCount"
            data-test-id="rating-sum"
          />
          <div v-if="scoreGroup" class="mt-6">
            <div
              v-for="(score, index) in scoreGroup"
              :key="index"
              class="flex flex-nowrap my-3 justify-between cursor-pointer"
              data-test-id="rating-list"
              @click="getReviewsListByStar(score[0])"
            >
              <div class="w-1/6">
                <span class="mr-3 w-3 inline-block">{{ score[0] }}</span
                ><StarIcon class="inline-block -mt-1 h-5" />
              </div>
              <div class="relative w-4/6">
                <span class="block bg-br-green" :style="{ width: getBarWidth(score[1]) }">&nbsp;</span>
              </div>
              <em class="block w-1/6 pl-4 not-italic fluid-sm tracking-wider font-medium align-top"
                >({{ score[1] }})</em
              >
            </div>
            <div class="-my-6 md:my-0">
              <TrustedShopsLogoIcon class="w-1/4" />
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 lg:w-2/3 lg:pl-32 md:-my-5">
          <div v-for="review in reviews" :key="review.UID" class="flex flex-wrap bg-white p-4 my-5">
            <div class="flex flex-nowrap w-full">
              <div class="w-1/2">
                <RatingStars :rating-value="parseInt(review.mark)" data-test-id="review-stars" />
              </div>
              <div class="w-1/2 text-right fluid-sm tracking-wider font-medium">
                {{ $d(new Date(review.creationDate)) }}
              </div>
            </div>
            <strong
              v-if="review.reviewer"
              class="w-full block fluid-sm tracking-wider font-medium mt-2"
              data-test-id="review-author"
            >
              {{ review.reviewer.profile.firstname }}
              {{ review.reviewer.profile.lastname }}
            </strong>
            <div class="w-full fluid-base leading-relaxed font-light mt-2" data-test-id="review-body">
              {{ review.comment }}
            </div>
          </div>
          <div v-if="isMoreToLoad" class="w-full text-center mt-12" data-test-id="load-more">
            <button class="btn-pill-outline-black relative" @click="loadMore">{{ $t('product.loadMore') }}</button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full text-center fluid-3xl leading-tightest text-gray-600 my-20 flex flex-wrap justify-center"
      >
        <div class="flex flex-nowrap justify-center w-1/2 sm:w-1/3 md:w-1/6">
          <StarIcon
            v-for="index in 5"
            :key="index"
            fill="#4b5563"
            class="star"
            width="100%"
            height="100%"
            :style="{ 'margin-right': '10px' }"
          />
        </div>
        <div class="fluid-lg leading-tight font-medium w-full mt-3">{{ $t('product.noReviews') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as onClickOutside } from 'vue-on-click-outside';
import RatingStars from '@/components/shop/products/RatingStars';
import StarIcon from '@/components/icons/StarIcon';
import TrustedShopsLogoIcon from '@/components/icons/TrustedShopsLogoIcon';
import tsMixin from '@/mixins/trustedShops';

export default {
  components: { TrustedShopsLogoIcon, StarIcon, RatingStars },
  mixins: [onClickOutside, tsMixin],
  props: {
    product: {
      type: Object,
      default: null,
    },
    tsProduct: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return { currentPage: 6, currentScore: false, toggleFilter: false };
  },
  computed: {
    productReviews() {
      return this.tsProduct?.reviews;
    },
    reviews() {
      return this.productReviews
        ?.filter((item) => !!item.comment && this.requiredScoreValue(item.mark))
        .slice(0, this.currentPage);
    },
    isMoreToLoad() {
      if (this.currentScore) return this.currentPage < this.getCountByRating(this.currentScore);
      return this.currentPage < this.totalCount;
    },
  },
  methods: {
    closePopup() {
      this.toggleFilter = false;
    },
    getCountByRating(rating) {
      return this.scoreGroup.size ? this.scoreGroup.get(rating) : 0;
    },
    requiredScoreValue(element) {
      return this.currentScore ? parseInt(element) === this.currentScore : true;
    },
    getReviewsListByStar(score) {
      this.currentScore = score;
      this.currentPage = 6;
      this.closePopup();
    },
    getBarWidth(divider) {
      const maxVal = Math.max(...this.scoreGroup.values());
      return (100 * divider) / maxVal + '%';
    },
    loadMore() {
      this.currentPage += 6;
    },
  },
};
</script>
