import { validObjectId } from '@/utils/algolia';
import { transformSiteVariable } from '@/plugins/craftClient';

export default function ({ env, app }, inject) {
  const fetchSingleAlgoliaObject = async function (objectID, indexName) {
    if (!validObjectId(objectID)) throw new TypeError('argument is not a valid algolia objectId');

    const { algoliaApplicationId, algoliaSearchApiKey } = env;
    const url = `https://${algoliaApplicationId}-dsn.algolia.net/1/indexes/${indexName}/${objectID}?attributesToRetrieve=*`;

    const response = await fetch(url, {
      headers: {
        'X-Algolia-API-Key': algoliaSearchApiKey,
        'X-Algolia-Application-Id': algoliaApplicationId,
      },
    });

    if (!response.ok) {
      throw new Error(
        `${indexName} msg: ${response?.statusText}, algoliaApplicationId: ${algoliaApplicationId}, index: ${indexName}, objectId: ${objectID}`
      );
    }

    return response.json();
  };

  const fetchMultipleAlgoliaObjects = async function (objectIds, indexName) {
    const { algoliaApplicationId, algoliaSearchApiKey } = env;

    const url = `https://${algoliaApplicationId}-dsn.algolia.net/1/indexes/*/objects`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        requests: objectIds.map((objectID) => ({
          indexName,
          objectID,
          attributesToRetrieve: '*',
        })),
      }),
      headers: {
        'X-Algolia-API-Key': algoliaSearchApiKey,
        'X-Algolia-Application-Id': algoliaApplicationId,
      },
    });

    if (!response.ok) {
      throw new Error(
        `${indexName} msg: ${response?.statusText}, algoliaApplicationId: ${algoliaApplicationId}, index: ${indexName}`
      );
    }

    return response.json();
  };

  const algoliaWorkouts = {
    fetchExercises(objectIds) {
      const locale = transformSiteVariable({ site: app.i18n.locale }).site;

      return fetchMultipleAlgoliaObjects(objectIds, `feg_exercise_${locale}`);
    },
    fetchExerciseById(objectID) {
      const locale = transformSiteVariable({ site: app.i18n.locale }).site;

      return fetchSingleAlgoliaObject(objectID, `feg_exercise_${locale}`);
    },
    fetchRoutineById(objectID) {
      const locale = transformSiteVariable({ site: app.i18n.locale }).site;

      return fetchSingleAlgoliaObject(objectID, `routine_${locale}`);
    },
    fetchFegWorkoutById(objectID) {
      const locale = transformSiteVariable({ site: app.i18n.locale }).site;

      return fetchSingleAlgoliaObject(objectID, `feg_workout_${locale}`);
    },
    fetchCourseById(objectID) {
      const locale = transformSiteVariable({ site: app.i18n.locale }).site;

      return fetchSingleAlgoliaObject(objectID, `course_contents_learnworlds_${locale}`);
    },
  };
  inject('algoliaWorkouts', algoliaWorkouts);
}
