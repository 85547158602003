<template>
  <div class="md:mx-8 my-l lg:container mx-3">
    <div v-if="heading || cta[0]" class="md:flex-nowrap md:mb-4 lg:mb-8 flex flex-wrap mb-2">
      <h4 v-if="heading" class="md:w-1/2 w-full">{{ heading }}</h4>
      <div v-if="cta[0]" class="md:w-1/2 md:flex flex-col-reverse hidden w-full text-right">
        <CtaArrow v-bind="cta[0]" class="cta-arrow" />
      </div>
    </div>
    <div class="md:grid gap-x-10 gap-y-5 xl:gap-x-20 grid-cols-2">
      <FixedAspect
        v-for="(imageTitleFeature, index) in imageTitleFeatures"
        :key="index"
        :ratio="6 / 5"
        class="md:py-0 group relative py-4 overflow-hidden"
        data-test-id="image-title-feature"
        v-bind="{ typeHandle }"
      >
        <LinkField
          class="relative"
          :to="productDetailUri(imageTitleFeature)"
          type-name="ImageTitleFeature"
          :tracking-name="imageTitleFeature ? stripHtml(imageTitleFeature.heading) : 'Unknown Heading'"
          :block-position="blockPosition"
        >
          <CldnImg
            v-if="imageTitleFeature.image[0]"
            :src="imageTitleFeature.image[0].url"
            :alt="imageTitleFeature.alt ? imageTitleFeature.alt : imageTitleFeature.image[0].title"
            crop="fill"
            :ratio="6 / 5"
            sizes="(min-width: 1440px) 608px,(min-width: 768px) 45vw, 100vw"
            class="group-hover:scale-110 object-cover w-full h-full transition-transform duration-700 transform"
          />
          <div class="mb-11 lg:mx-7 lg:mb-10 absolute bottom-0 px-1 mx-3">
            <div v-if="featureTags(imageTitleFeature)">
              <template v-for="category in featureTags(imageTitleFeature)">
                <TagBtn :key="category.title" :to="{ path: category.uri }" class="lg:mt-4 inline-block mb-3">{{
                  category.title
                }}</TagBtn>
              </template>
            </div>
            <BarText
              v-if="imageTitleFeature.heading"
              class="fluid-4xl leading-none font-semibold cursor-pointer pointer-events-none"
              :text="imageTitleFeature.heading"
            />
          </div>
        </LinkField>
      </FixedAspect>
    </div>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import BarText from '@/components/BarText';
import CtaArrow from '@/components/common/CtaArrow';
import TagBtn from '@/components/common/TagBtn';
import LinkField from '@/components/common/LinkField';
import { stripHtml } from '@/utils/common';

export default {
  components: {
    LinkField,
    TagBtn,
    BarText,
    CldnImg,
    CtaArrow,
    FixedAspect,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
    imageTitleFeatures: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
    typeHandle: {
      type: String,
      default: 'imageTitleFeature',
    },
  },
  methods: {
    stripHtml,
    productDetailUri(imageTitleFeature) {
      return this.$localeRootPath(imageTitleFeature?.page[0]?.uri || imageTitleFeature?.page[0]?.element?.uri);
    },
    featureTags(feature) {
      return feature.story && feature.story[0]?.storiesCategories;
    },
  },
};
</script>
