<template>
  <div class="my-l container p-0">
    <div class="sm:flex-row relative flex flex-col">
      <nuxt-link
        v-if="featuredRoute"
        :to="featuredRoute"
        class="sm:block lg:mt-8 lg:ml-8 btn-pill-black px-6 min-w-[9rem] absolute top-0 left-0 z-10 hidden mt-4 ml-4 fluid-base leading-relaxed font-light uppercase border-none"
        >{{ $t('featured') }}</nuxt-link
      >
      <FixedAspect :ratio="2 / 3" class="sm:hidden w-full">
        <CldnImg
          v-if="image[0]"
          :src="image[0].url"
          :alt="alt ? alt : image[0].title"
          sizes="100vw"
          :ratio="2 / 3"
          crop="fill"
        />
      </FixedAspect>
      <FixedAspect :ratio="6 / 5" class="sm:block hidden w-1/2">
        <CldnImg
          v-if="image[0]"
          :src="image[0].url"
          :alt="alt ? alt : image[0].title"
          sizes="(min-width: 1440px) 648px, (min-width: 1024px) 30vw, 50vw"
          :ratio="6 / 5"
          crop="fill"
        />
      </FixedAspect>
      <div class="sm:w-1/2 py-12 xl:px-24 flex flex-col items-start justify-center w-full box">
        <div class="flex flex-row items-center space-x-4">
          <template v-if="featuredArticle">
            <TagBtn v-if="featuredArticle.topics[0]" :to="$localeRootPath(featuredArticle.topics[0].uri)">
              {{ featuredArticle.topics[0].title }}
            </TagBtn>
            <TagBtn
              v-if="featuredArticle.categories[0]"
              :to="{
                name: `knowledge-index-category-category___${$i18n.locale}`,
                params: { category: featuredArticle.categories[0].slug },
              }"
              secondary
            >
              {{ featuredArticle.categories[0].title }}
            </TagBtn>
            <div class="fluid-xs leading-tightest">{{ $t('readTime', { min: featuredArticle.readTime }) }}</div>
          </template>
        </div>

        <h2 class="fluid-3xl leading-none mt-2" data-test-id="header">{{ headingPrimary }}</h2>
        <div class="sm:mt-6 mt-4 fluid-lg font-light leading-normal" data-test-id="body" v-html="teaserBody"></div>
        <LinkField
          v-if="cta[0] && !featuredRoute"
          v-bind="cta[0]"
          class="sm:mt-6 btn-pill-black inline-block mt-4"
          type-name="FiftyFiftyArticleTeaser"
          :tracking-name="headingPrimary"
          data-test-id="cta"
        />
        <nuxt-link v-if="featuredRoute" :to="featuredRoute" class="sm:mt-6 btn-pill-black inline-block mt-4">{{
          $t('readMore')
        }}</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import TagBtn from '@/components/common/TagBtn';
import LinkField from '@/components/common/LinkField';

export default {
  components: {
    TagBtn,
    FixedAspect,
    CldnImg,
    LinkField,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
    article: {
      type: Array,
      default: () => [],
    },
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    featuredArticle() {
      return this.article && this.article[0] ? this.article[0] : null;
    },
    featuredRoute() {
      return this.featuredArticle ? this.$localeRootPath(this.featuredArticle.uri) : null;
    },
    headingPrimary() {
      return this.heading || (this.featuredArticle && this.featuredArticle.title);
    },
    teaserBody() {
      return this.body || (this.featuredArticle && this.featuredArticle.teaserText);
    },
  },
};
</script>
