<template>
  <div v-if="featureItems && featureItems.length >= 2" class="py-20 container">
    <h2 class="fluid-3xl leading-tightest text-center md:text-left">{{ heading }}</h2>

    <div class="flex flex-wrap md:flex-nowrap gap-5 md:gap-10 mt-10 justify-around">
      <div v-for="feature in featureItems" :key="feature.id" class="flex md:max-w-md">
        <div v-if="feature.icon[0]" class="w-10 mr-6 shrink-0">
          <img class="h-10 w-10" :src="feature.icon[0].url" :alt="feature.icon[0].alt ?? 'Icon'" />
        </div>
        <div>
          <h3 class="text-sm md:text-lg font-bold">{{ feature.heading }}</h3>
          <div class="mt-2 text-sm leading-6 md:leading-7 md:text-base" v-html="feature.description"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    featureItems: {
      type: Array,
      required: true,
    },
  },
};
</script>
