<template>
  <div class="bg-blackly lg:grid-cols-2 relative grid">
    <CldnImg
      v-if="image[0]"
      :src="image[0].url"
      :sizes="'(min-width: 1024px) 50vw, 100vw'"
      class="max-w-none block object-cover w-full h-full"
      :crop="'fill'"
      :ratio="1.2"
      :alt="alt ? alt : image[0].title"
    />
    <div
      class="lg:w-auto lg:mx-auto lg:max-w-xl lg:flex lg:items-center lg:justify-center lg:px-12 container py-12 text-white"
    >
      <div>
        <h2 class="fluid-4xl leading-none lg:mb-10 mb-6">
          {{ heading }}
        </h2>
        <div v-if="body" class="text-copy fluid-base leading-relaxed font-light" v-html="body"></div>
        <LinkField v-if="cta[0]" :tracking-name="heading" v-bind="cta[0]" class="btn-pill-outline-white mt-6" />
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import LinkField from '@/components/common/LinkField';

export default {
  components: { CldnImg, LinkField },
  props: {
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
};
</script>
