<template>
  <div class="container">
    <div class="max-w-screen-md mx-auto">
      <div class="border-b border-gray-400">
        <button class="h-14 focus:outline-none flex items-center justify-between w-full" @click="open = !open">
          <div class="fluid-base leading-relaxed font-light pr-2 text-left">
            {{ heading }}
          </div>
          <RemoveIcon v-if="open" class="h-6 shrink-0" />
          <AddIcon v-if="!open" class="h-6 shrink-0" />
        </button>
        <Expandable :open="open" :duration="300">
          <div class="fluid-base leading-relaxed font-light text-copy pt-2 pb-8" v-html="body"></div>
        </Expandable>
      </div>
    </div>
  </div>
</template>

<script>
import Expandable from '@/components/common/Expandable';
import AddIcon from '@/components/icons/AddIcon';
import RemoveIcon from '@/components/icons/RemoveIcon';

export default {
  components: {
    Expandable,
    AddIcon,
    RemoveIcon,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      open: false,
    };
  },
};
</script>
