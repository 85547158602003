<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="{
          product,
          tsProduct,
          description: product.descriptionHtml,
          accordions,
          addOn,
          youtubeId: product.video,
          exercisePage,
          testimonials: product.testimonial,
          handles: product.relatedProducts,
          handle: product.bundleProduct,
          features: product.keyFeatures,
          agreementCategory: block.agreementCategory ? block.agreementCategory : agreementCategory,
          blockPosition: index,
          heading: block.typeHandle === 'accordion' ? $t('shop.accordionHeading') : null,
          backgroundGreen: block.typeHandle === 'accordion',
          ...block,
        }"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import guestComponent from '@/mixins/guestComponent';

import ConversionAreaOne, {
  MarketingBlocksConversionAreaOneFragment,
} from '@/components/common/blocks/ConversionAreaOne';
import AccordionBlock, {
  ProductBlocksAccordionBlockFragment,
  ProductBlocksAccordionFragment,
} from '@/components/common/blocks/AccordionBlock';
import AppCta, { MarketingBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import VideoFeature, { ProductBlocksVideoFeatureFragment } from '~/components/common/blocks/VideoFeature';
import ExerciseTeaser, { ProductBlocksExerciseTeaserFragment } from '@/components/common/blocks/ExerciseTeaser';
import ExpertTestimonial, { ProductBlocksExpertFragment } from '~/components/common/blocks/ExpertTestimonial';
import RelatedProducts, { ProductBlocksRelatedProductsFragment } from '@/components/common/blocks/RelatedProducts';
import ProductInformation, {
  ProductBlocksProductInformationFragment,
} from '@/components/common/blocks/ProductInformation';
import ProductReviews, { MarketingBlocksProductReviewsFragment } from '@/components/common/blocks/ProductReviews';
import AlsoBought, { ProductBlocksAlsoBoughtFragment } from '@/components/common/blocks/AlsoBought';
import ImageText, { MarketingBlocksImageTextFragment } from '~/components/common/blocks/ImageText';
import SplitContentSlider, {
  MarketingBlocksSplitContentSliderFragment,
} from '@/components/common/blocks/SplitContentSlider';
import GenericIframe, { MarketingBlocksGenericIframeFragment } from '@/components/common/blocks/GenericIframe';
import TeaserWithCta, { ProductBlocksTeaserWithCtaFragment } from '@/components/common/blocks/TeaserWithCta';
import NewsletterSignupForm, {
  MarketingBlocksNewsletterSignupFormFragment,
} from '@/components/common/blocks/NewsletterSignupForm';
import CenteredContent from '@/components/common/blocks/CenteredContent';
import { MarketingBlocksCenteredContentFragment } from '@/components/common/blocks/CenteredContent/index';
import VideoWithDescription, {
  ExercisesBlocksVideoWithDescriptionFragment,
} from '@/components/common/blocks/VideoWithDescription';
import CourseConversionBanner, {
  MarketingBlocksCourseConversionBannerFragment,
} from '~/components/common/blocks/CourseConversionBanner';
import FeatureList, { MarketingBlocksFeatureListFragment } from '@/components/common/blocks/FeatureList';
import InsuranceReimbursement, {
  MarketingBlocksInsuranceReimbursementFragment,
} from '@/components/common/blocks/InsuranceReimbursement';
import CourseContent, { MarketingBlocksCourseContentFragment } from '~/components/common/blocks/CourseContent';
import CrossSell, { MarketingBlocksCrossSellFragment } from '@/components/common/blocks/CrossSell';
import Upsell, { MarketingBlocksUpsellFragment } from '@/components/common/blocks/Upsell';
import TestimonialWithVariedBackground, {
  MarketingBlocksTestimonialWithVariedBackgroundFragment,
} from '@/components/common/blocks/TestimonialWithVariedBackground';
import Gallery, { GalleryFragment } from '~/components/common/blocks/Gallery';
import FeaturesWithIcons, {
  ProductBlocksFeaturesWithIconsFragment,
} from '@/components/common/blocks/FeaturesWithIcons';

export const ProductBlocksMatrixFragment = gql`
  fragment ProductBlocksMatrixFragment on productBlocks_MatrixField {
    ...MarketingBlocksConversionAreaOneFragment
    ...ProductBlocksAccordionFragment
    ...MarketingBlocksAppCtaFragment
    ...ProductBlocksVideoFeatureFragment
    ...ProductBlocksExerciseTeaserFragment
    ...ProductBlocksExpertFragment
    ...ProductBlocksRelatedProductsFragment
    ...ProductBlocksProductInformationFragment
    ...MarketingBlocksProductReviewsFragment
    ...ProductBlocksAlsoBoughtFragment
    ...MarketingBlocksImageTextFragment
    ...MarketingBlocksSplitContentSliderFragment
    ...ProductBlocksAccordionBlockFragment
    ...MarketingBlocksGenericIframeFragment
    ...ProductBlocksTeaserWithCtaFragment
    ...MarketingBlocksNewsletterSignupFormFragment
    ...MarketingBlocksCenteredContentFragment
    ...ExercisesBlocksVideoWithDescriptionFragment
    ...MarketingBlocksCourseConversionBannerFragment
    ...MarketingBlocksFeatureListFragment
    ...MarketingBlocksInsuranceReimbursementFragment
    ...MarketingBlocksCourseContentFragment
    ...MarketingBlocksUpsellFragment
    ...MarketingBlocksCrossSellFragment
    ...MarketingBlocksTestimonialWithVariedBackgroundFragment
    ...GalleryFragment
    ...ProductBlocksFeaturesWithIconsFragment
  }
  ${MarketingBlocksConversionAreaOneFragment}
  ${ProductBlocksAccordionFragment}
  ${MarketingBlocksAppCtaFragment}
  ${ProductBlocksVideoFeatureFragment}
  ${ProductBlocksExerciseTeaserFragment}
  ${ProductBlocksExpertFragment}
  ${ProductBlocksRelatedProductsFragment}
  ${ProductBlocksProductInformationFragment}
  ${MarketingBlocksProductReviewsFragment}
  ${ProductBlocksAlsoBoughtFragment}
  ${MarketingBlocksImageTextFragment}
  ${MarketingBlocksSplitContentSliderFragment}
  ${ProductBlocksAccordionBlockFragment}
  ${MarketingBlocksGenericIframeFragment}
  ${ProductBlocksTeaserWithCtaFragment}
  ${MarketingBlocksNewsletterSignupFormFragment}
  ${MarketingBlocksCenteredContentFragment}
  ${MarketingBlocksCourseConversionBannerFragment}
  ${ExercisesBlocksVideoWithDescriptionFragment}
  ${MarketingBlocksFeatureListFragment}
  ${MarketingBlocksInsuranceReimbursementFragment}
  ${MarketingBlocksCourseContentFragment}
  ${MarketingBlocksUpsellFragment}
  ${MarketingBlocksCrossSellFragment}
  ${MarketingBlocksTestimonialWithVariedBackgroundFragment}
  ${GalleryFragment}
  ${ProductBlocksFeaturesWithIconsFragment}
`;

export default {
  name: 'ProductBlocks',
  components: {
    GenericIframe,
    Accordion: AccordionBlock,
    AccordionBlock,
    ConversionAreaOne,
    AppCta,
    VideoFeature,
    ExerciseTeaser,
    ExpertTestimonial,
    RelatedProducts,
    ProductInformation,
    ProductReviews,
    AlsoBought,
    ImageText,
    SplitContentSlider,
    CenteredContent,
    TeaserWithCta,
    NewsletterSignupForm,
    FeatureList,
    CourseConversionBanner,
    VideoWithDescription,
    InsuranceReimbursement,
    CourseContent,
    CrossSell,
    Upsell,
    TestimonialWithVariedBackground,
    Gallery,
    FeaturesWithIcons,
  },
  mixins: [guestComponent],
  props: {
    entry: {
      type: Object,
      default: () => null,
    },
    product: {
      type: Object,
      default: () => null,
    },
    tsProduct: {
      type: Object,
      default: () => null,
    },
    addOn: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    exercisePage() {
      return this.entry?.exercisePage;
    },
    agreementCategory() {
      return this.entry?.agreementCategory;
    },
    accordions() {
      return (
        this.product.accordion?.map((f) => ({
          heading: f.title,
          body: f.description,
        })) || []
      );
    },
    blocks() {
      return this.entry && this.entry.productBlocks ? this.entry.productBlocks : [];
    },
  },
  methods: {
    tsIsSet() {
      const { locale, locales } = this.$i18n;
      const tsid = locales.find((l) => l.code === locale)?.tsid;
      return !!tsid;
    },
  },
};
</script>
