<template>
  <ImageTitleFeature v-bind="$props" />
</template>
<script>
import ImageTitleFeature from '@/components/common/blocks/ImageTitleFeature';

export default {
  components: { ImageTitleFeature },
  props: {
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
    imageTitleFeatures: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
