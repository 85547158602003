<template>
  <div class="lg:relative" :class="{ 'xl:container': !fullBleed }">
    <FixedAspect :ratio="0.45" class="lg:block hidden">
      <CldnImg
        v-if="image[0]"
        :src="image[0].url"
        :alt="alt ? alt : image[0].title"
        crop="fill"
        :ratio="0.45"
        sizes="(min-width: 1440px) 1296px, 100vw"
      />
    </FixedAspect>
    <FixedAspect :ratio="0.55" class="lg:hidden block">
      <CldnImg
        v-if="image[0]"
        :src="image[0].url"
        :alt="alt ? alt : image[0].title"
        crop="fill"
        :ratio="0.55"
        sizes="100vw"
      />
    </FixedAspect>
    <div
      class="bg-blackly lg:absolute top-1/2 right-12 lg:w-2/5 lg:px-10 xl:px-14 xl:py-12 z-10 px-5 py-10 text-white lg:translate-y-[-50%]"
    >
      <h2 v-if="heading">{{ heading }}</h2>
      <p v-if="body" class="fluid-base leading-relaxed font-light py-3">{{ body }}</p>
      <ProductComparePriceByHandle
        v-if="handle"
        :handle="handle"
        price-class="fluid-xl leading-tight font-medium h-10"
        compare-price-class="fluid-lg leading-tight font-medium"
      />
      <LinkField v-bind="cta[0]" class="btn-pill-black mt-5" />
    </div>
  </div>
</template>

<script>
import LinkField from '@/components/common/LinkField';
import CldnImg from '@/components/CldnImg';
import ProductComparePriceByHandle from '@/components/common/ProductComparePriceByHandle';
import FixedAspect from '@/components/common/FixedAspect';

export default {
  components: {
    FixedAspect,
    ProductComparePriceByHandle,
    LinkField,
    CldnImg,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    image: {
      type: Array,
      default: () => [],
    },
    cta: {
      type: Array,
      default: () => [],
    },
    fullBleed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { price: null };
  },
  computed: {
    /**
     * extract handle from element linked in cta only on linked product
     */
    handle() {
      const elem = this.cta[0]?.element;
      return elem?.sectionHandle === 'products' ? elem.shopifyHandle : null;
    },
  },
};
</script>
