import gql from 'graphql-tag';
import { NavBlocksFragment } from '@/components/layout/header/navBlocks/NavBlocks';

export const HelpNavFragment = gql`
  fragment HelpNavFragment on helpNav_GlobalSet {
    navHeading
    links: linkList {
      ... on linkListBlock_Entry {
        linkField {
          ...LinkFragment
        }
      }
    }
  }
`;
export const MainNavTopLevelEntryFragment = gql`
  fragment MainNavTopLevelEntryFragment on toplevel_Entry {
    typeHandle
    title
    link: navLink {
      ...LinkFragment
    }
    navBlocks {
      ...NavBlocksFragment
    }
  }
  ${NavBlocksFragment}
`;

export const state = () => ({
  isOpen: false, // mobile drawer open state
  openNavTopIndex: null, // desktop drawer open topMenu index
  navTops: [],
  benefits: [],
  helpNav: null,
  alternates: {},
  contactWidget: null,
});

export const actions = {
  open({ commit }) {
    const data = {
      event: 'gaEvent',
      event_name: 'navigation_mobile_unfold',
      navigation: {
        navigation_interaction: 'unfold',
        navigation_item: 'mobile_menu',
        event_category: 'Navigation',
        event_action: 'Mobile Menu',
        event_label: 'unfold',
      },
    };
    this.$_gtm.push(data);
    commit('OPEN');
  },
  close({ commit }) {
    const data = {
      event: 'gaEvent',
      event_name: 'navigation_mobile_fold',
      navigation: {
        navigation_interaction: 'fold',
        navigation_item: 'mobile_menu',
        event_category: 'Navigation',
        event_action: 'Mobile Menu',
        event_label: 'fold',
      },
    };
    this.$_gtm.push(data);
    commit('CLOSE');
  },
  setOpenNavTopIndex({ commit }, index = null) {
    commit('SET_OPEN_NAV_TOP_INDEX', index);
  },
};
export const mutations = {
  INIT(state, navTops) {
    state.navTops = navTops;
    state.loading = false;
  },
  OPEN(state) {
    state.isOpen = true;
  },
  CLOSE(state) {
    state.isOpen = false;
  },
  SET_OPEN_NAV_TOP_INDEX(state, index) {
    state.openNavTopIndex = index;
  },
  SET_HELP_NAV(state, helpNav) {
    state.helpNav = helpNav;
  },
  SET_BENEFITS_NAV(state, benefits) {
    state.benefits = benefits;
  },
  SET_ALTERNATES(state, alternates) {
    if (alternates.length === 0) {
      alternates = this.$i18n.locales.reduce((acc, cur) => {
        if (cur.code !== this.$i18n.locale) {
          acc[cur.code] = this.switchLocalePath(cur.code);
        }
        return acc;
      }, {});
    }
    state.alternates = alternates === null ? {} : alternates;
  },

  SET_CONTACT_WIDGET(state, contactWidget) {
    state.contactWidget = contactWidget;
  },
};

export const getters = {
  navTops(state) {
    return state.navTops;
  },
  alternates(state) {
    return state.alternates;
  },
  hasAlternates(state) {
    try {
      return state.alternates && Object.entries(state.alternates).length > 0;
    } catch (e) {
      return false;
    }
  },
};
