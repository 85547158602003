<template>
  <component :is="component" v-if="component" :style="gridItemStyle" :class="gridColumnSpan" v-bind="block" />
</template>

<script>
import gql from 'graphql-tag';
import OneByOneBox, { GridBlocksOneByOneBoxFragment } from '@/components/shop/collections/gridBlocks/OneByOneBox';
import OneByOneImageCta, {
  GridBlocksOneByOneImageCtaFragment,
} from '@/components/shop/collections/gridBlocks/OneByOneImageCta';
import TwoByOneImageCta, {
  GridBlocksTwoByOneImageCtaFragment,
} from '@/components/shop/collections/gridBlocks/TwoByOneImageCta';
import TwoByTwoImageCta, {
  GridBlocksTwoByTwoImageCtaFragment,
} from '@/components/shop/collections/gridBlocks/TwoByTwoImageCta';

export const GridBlocksMatrixFragment = gql`
  fragment GridBlocksMatrixFragment on gridBlocks_MatrixField {
    ...GridBlocksOneByOneImageCtaFragment
    ...GridBlocksTwoByOneImageCtaFragment
    ...GridBlocksTwoByTwoImageCtaFragment
    ...GridBlocksOneByOneBoxFragment
  }
  ${GridBlocksOneByOneImageCtaFragment}
  ${GridBlocksTwoByOneImageCtaFragment}
  ${GridBlocksTwoByTwoImageCtaFragment}
  ${GridBlocksOneByOneBoxFragment}
`;

export default {
  props: {
    block: {
      type: Object,
      default: null,
    },
  },
  computed: {
    component() {
      if (this.block?.typeHandle === 'oneByOneImageCta') return OneByOneImageCta;
      if (this.block?.typeHandle === 'twoByOneImageCta') return TwoByOneImageCta;
      if (this.block?.typeHandle === 'twoByTwoImageCta') return TwoByTwoImageCta;
      if (this.block?.typeHandle === 'oneByOneBox') return OneByOneBox;
      return null;
    },
    gridItemStyle() {
      let style = '';
      if (this.block?.position) style += `order: ${this.block.position};`;
      if (this.component?.rows) style += `grid-row: span ${this.component.rows};`;
      return style;
    },
    gridColumnSpan() {
      let classes = 'col-span-full ';
      if (this.component?.cols) classes += `md:col-span-${this.component.cols}`;
      return classes;
    },
  },
};
</script>
