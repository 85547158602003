export const stripTrailingSlash = (str) => {
  return str.endsWith('/') ? str.slice(0, -1) : str;
};
export const indexPad = (num) => {
  if (typeof num !== 'number') return '';
  return num.toString(10).padStart(2, '0');
};

/**
 * remove craft-convention for home-uri "__home__" from home-entry uri
 * @param {string} uri
 * @returns string
 */
export const sanitizeHomeUri = (uri) => (uri === '__home__' ? '' : uri);

/**
 * test for html element
 * @param {*} element
 * @returns boolean
 */
export const isElement = (element) => element instanceof Element || element instanceof HTMLDocument;

export const scrollToSelectorRespectingBars = (selector, additionalOffset = 0) => {
  let target = null;

  try {
    target = document.querySelector(selector);
  } catch (e) {
    return;
  }

  if (!target) return;

  const chapterNavBarHeight = document.querySelector('#ChapterNavBar')?.getBoundingClientRect()?.height ?? 0;
  const pageHeaderHeight = document.querySelector('#PageHeader')?.getBoundingClientRect()?.height ?? 0;

  const offset =
    target.getBoundingClientRect().top +
    window.pageYOffset +
    additionalOffset -
    (chapterNavBarHeight + pageHeaderHeight);

  window.scrollTo({ top: offset, behavior: 'smooth' });
};

export const removeFileExtension = (path) => {
  const lastDotIndex = path.lastIndexOf('.');
  if (lastDotIndex === -1) return path;
  return path.substr(0, lastDotIndex);
};

// Function to remove HTML Tags from a string
export const stripHtml = (string) => {
  if (typeof string === 'string') {
    return string.replace(/(<([^>]+)>)/gi, '').replaceAll('\n', '');
  }

  return '';
};

export const normalizeTypeHandles = (blocks) => {
  blocks.forEach((block) => {
    block.typeHandle = block.typeHandle.replace(/\d+$/g, '').trim();
  });

  return blocks;
};
