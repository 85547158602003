<template>
  <div>
    <figure class="group">
      <nuxt-link
        v-if="topic[0] && topic[0].element"
        class="relative block overflow-hidden"
        :to="$localeRootPath(topic[0].element.uri)"
      >
        <FixedAspect :ratio="4 / 3" class="hidden md:block">
          <CldnImg
            v-if="image[0]"
            :src="image[0].url"
            :alt="alt ? alt : image[0].title"
            crop="fill"
            :ratio="4 / 3"
            sizes="(min-width: 1440px) 368px, (min-width: 768px) 30vw"
            class="background-image object-cover w-full transition-transform duration-700 transform group-hover:scale-110"
          />
        </FixedAspect>
        <FixedAspect :ratio="2 / 3" class="block md:hidden">
          <CldnImg
            v-if="image[0]"
            :src="image[0].url"
            :alt="alt ? alt : image[0].title"
            :ratio="2 / 3"
            crop="fill"
            sizes="100vw"
            class="background-image object-cover w-full"
          />
        </FixedAspect>
        <figcaption v-if="heading" class="md:p-10 absolute bottom-0 p-4 z-10">
          <BarText tag="div" class="fluid-3xl leading-tightest font-semibold" :text="heading" />
        </figcaption>
        <div
          class="overlay bg-blackly absolute inset-0 transition-opacity duration-700 opacity-0 pointer-events-none group-hover:opacity-25"
        ></div>
      </nuxt-link>
    </figure>
    <div class="w-full text-right py-3">
      <CtaArrow v-bind="topic" class="cta-arrow" tabindex="-1" />
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import BarText from '@/components/BarText';
import CtaArrow from '@/components/common/CtaArrow';

export default {
  components: { CtaArrow, BarText, CldnImg, FixedAspect },
  props: {
    topic: {
      type: Array,
      default: () => [],
      required: true,
    },
    image: {
      type: Array,
      default: () => [],
      required: true,
    },
    alt: {
      type: String,
      default: '',
      required: false,
    },
    heading: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
