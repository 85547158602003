<template>
  <ul
    v-if="navTops"
    class="lg:flex main-nav justify-center hidden w-full h-full"
    :class="{ 'is-open': openNavTopIndex !== null }"
    @mouseleave="setOpenNavTopIndex(null)"
  >
    <li
      v-for="(navTop, idx) in navTops"
      :key="idx"
      class="main-nav-item"
      data-testid="main-nav-top"
      :class="{ 'is-active': openNavTopIndex === idx }"
      @mouseenter="navTop.navBlocks && navTop.navBlocks.length > 0 ? setOpenNavTopIndex(idx) : setOpenNavTopIndex(null)"
    >
      <LinkField
        v-bind="navTop.link[0]"
        class="lg:px-8 fluid-xs leading-snug uppercase font-medium main-nav-item-link text-blackly-dark relative flex items-center h-full px-4"
        :active-class="'color-br-green is-active'"
        @click.native="handleNavClick()"
      >
        {{ navTop.title }}
        <div class="indicator bg-br-green absolute bottom-0 left-0 right-0 transition-all opacity-0" />
      </LinkField>

      <div
        v-if="renderDrawerContent && navTop.navBlocks && navTop.navBlocks.length > 0"
        class="drawer absolute px-16 py-10"
      >
        <div class="drawer-content flex justify-center w-full" data-testid="main-nav-drawer">
          <NavBlocks :blocks="navTop.navBlocks" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import NavBlocks from '@/components/layout/header/navBlocks/NavBlocks';
import { mapState, mapActions } from 'vuex';
import LinkField from '@/components/common/LinkField';

/**
 * renders navTop items SSR, renders navBlocks (drawerContent) ClientSide only
 */
export default {
  components: {
    LinkField,
    NavBlocks,
  },
  data() {
    return {
      renderDrawerContent: false,
    };
  },
  computed: {
    ...mapState('mainNav', ['navTops', 'openNavTopIndex']),
  },
  mounted() {
    this.renderDrawerContent = true;
  },
  methods: {
    ...mapActions('mainNav', ['setOpenNavTopIndex']),
    handleNavClick() {
      const data = {
        event: 'gaEvent',
        event_name: 'navigation',
        navigation: {
          navigation_area: 'Header',
          navigation_item: 'first level navigation',
          event_category: 'Navigation',
          event_action: 'Header',
          event_label: 'first level navigation',
        },
      };
      this.$_gtm.push(data);
      this.$store.dispatch('mainNav/setOpenNavTopIndex');
    },
  },
};
</script>

<style scoped>
.indicator {
  @apply h-[3px] mb-[-1px] transition-all delay-[250];
}
.main-nav:not(.is-open) .main-nav-item-link.is-active .indicator {
  @apply opacity-100;
}
.main-nav-item.is-active .indicator,
.main-nav-item:hover .indicator {
  @apply opacity-100;
}

.drawer {
  @apply mt-[1px] left-0 right-0 top-full min-h-[300px] overflow-hidden opacity-0 invisible scale-y-0;
  background-color: #fcfcfc;
  transition: transform 100ms ease, opacity 0ms linear 100ms, visibility 0ms linear 100ms;
  transform-origin: top center;
}

.drawer-content {
  @apply opacity-0 translate-y-[-10px] delay-[0];
  transition: transform 0.1s, opacity 100ms;
}

.main-nav.is-open .drawer {
  @apply transition-none scale-y-100;
}

.main-nav-item.is-active .drawer {
  @apply flex opacity-100 visible scale-y-100;
  transition: transform 250ms ease, opacity 0ms, visibility 0ms;
}

.main-nav-item.is-active .drawer-content {
  @apply opacity-100 translate-y-0 delay-200;
  transition: transform 300ms ease, opacity 200ms linear;
}
</style>
