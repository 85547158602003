<template>
  <div class="border border-gray-400 border-b-0 px-6 py-5">
    <button class="focus:outline-none flex items-center justify-between w-full" @click="open = !open">
      <div class="fluid-lg leading-tight font-medium pr-2 text-left">
        <span class="mr-1 py-4">{{ pad(nb + 1) }}.</span>{{ heading }}
      </div>
      <CloseIcon v-if="open" class="h-6 shrink-0" />
      <AddIcon v-if="!open" class="h-6 shrink-0" />
    </button>
    <Expandable :open="open" :duration="300">
      <div class="fluid-base leading-relaxed font-light mt-6" v-html="body"></div>
      <div class="divide-x divide-black flex flex-row flex-nowrap mt-6">
        <span
          v-for="(cat, index) in categories"
          :key="index"
          class="block font-bold fluid-xs leading-tightest first:pl-0 px-2"
          >{{ cat.name }}: {{ cat.count }}</span
        >
      </div>
    </Expandable>
  </div>
</template>

<script>
import Expandable from '@/components/common/Expandable';
import AddIcon from '@/components/icons/AddIcon';
import CloseIcon from '~/components/icons/CloseIcon';

export default {
  components: {
    CloseIcon,
    Expandable,
    AddIcon,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    nb: {
      type: Number,
      default: 0,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: this.isOpen,
    };
  },
  methods: {
    pad(n) {
      return n < 10 ? '0' + n : n;
    },
  },
};
</script>
