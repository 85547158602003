<template>
  <div class="container my-l">
    <div v-if="heading || cta[0]" class="flex flex-wrap mb-5">
      <BarText v-if="heading" class="w-full md:w-1/2" :text="heading" tag="h2" />
      <div v-if="cta[0]" class="w-full md:w-1/2 text-right hidden md:flex flex-col-reverse">
        <CtaArrow v-bind="cta[0]" class="cta-arrow" />
      </div>
    </div>
    <div class="relative container grid grid-cols-1 md:grid-cols-3 content-start gap-10">
      <SmallTopic
        v-for="(topic, index) in topicCards"
        :key="index"
        v-bind="topic"
        v-track:view="gtmData(topic, index)"
        v-track:click="gtmData(topic, index)"
      />
    </div>
  </div>
</template>

<script>
import SmallTopic from '@/components/common/blocks/SmallTopic';
import CtaArrow from '@/components/common/CtaArrow';
import BarText from '@/components/BarText';

export default {
  components: { BarText, CtaArrow, SmallTopic },
  props: {
    heading: {
      type: String,
      default: '',
    },
    cta: {
      type: Array,
      default: () => [],
    },
    topicCards: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.topic.url,
        promotion_name: [this.$route?.name, payload.topic?.element?.title].join(' / '),
        creative_name: 'smallTopicFeatureBars',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>
