var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.image && _vm.image[0])?_c('div',{staticClass:"relative sm:py-12"},[_c('div',{staticClass:"sm:hidden"},[(_vm.image[0])?_c('CraftAsset',{attrs:{"asset":_vm.image[0],"ratio":0.55,"autoplay":true,"muted":true,"hfull":true,"render-covered":true,"sizes":"100vw","crop":"fill"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"hidden sm:block absolute inset-0 overflow-hidden"},[(_vm.image[0])?_c('CraftAsset',{attrs:{"asset":_vm.image[0],"autoplay":true,"muted":true,"hfull":true,"render-covered":true,"sizes":"100vw","crop":"fill"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"sm:container sm:h-full relative flex items-center z-10 sm:min-h-[26rem]",class:{ 'justify-end': _vm.boxPositionRight }},[_c('div',{staticClass:"p-6 lg:p-12 bg-blackly w-full sm:max-w-lg"},[(_vm.course)?_c('ProductProvider',{attrs:{"handle":_vm.course},scopedSlots:_vm._u([{key:"default",fn:function({ addToCartDisabled, addToCart, price, compareAtPrice, title, isCourse }){return [_c('div',{staticClass:"fluid-xl leading-tight font-semibold mb-4 text-white"},[_vm._v(_vm._s(title ?? _vm.heading))]),_vm._v(" "),(_vm.bodyText)?_c('div',{staticClass:"text-white mb-4",domProps:{"innerHTML":_vm._s(_vm.bodyText)}}):_vm._e(),_vm._v(" "),(price)?_c('div',{staticClass:"fluid-xl leading-tight font-medium text-br-green-light mb-6"},[(compareAtPrice && compareAtPrice.amount > 0)?_c('span',{staticClass:"text-gray-light inline-block line-through decoration-gray-light mr-2 fluid-xl leading-tight self-baseline font-light",attrs:{"data-test-id":"old-price"}},[_vm._v("\n            "+_vm._s(_vm.$n(compareAtPrice.amount, {
                style: 'currency',
                currency: compareAtPrice.currencyCode,
              }))+"\n          ")]):_vm._e(),_vm._v(" "),_c('span',{class:{ 'fluid-lg leading-tight font-medium': compareAtPrice }},[_vm._v(_vm._s(_vm.$n(price.amount, {
              style: 'currency',
              currency: price.currencyCode,
            })))]),_vm._v(" "),_c('em',{staticClass:"text-white fluid-base leading-relaxed font-light not-italic"},[_vm._v(_vm._s(_vm.$t('vatIncl')))])]):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn-pill-green whitespace-nowrap mr-1.5",class:{ 'btn-pill-gray pointer-events-none': addToCartDisabled },attrs:{"disabled":addToCartDisabled,"data-testid":"addToCart"},on:{"click":function($event){_vm.hasPreAssessment ? (_vm.showPreAssessmentModal = true) : addToCart()}}},[_vm._v("\n          "+_vm._s(isCourse ? _vm.$t('cart.bookNow') : _vm.$t('cart.addToCart'))+"\n        ")]),_vm._v(" "),_c('portal',{attrs:{"to":"modal"}},[(_vm.hasPreAssessment && _vm.showPreAssessmentModal)?_c('PreAssessmentModal',{attrs:{"showing":_vm.showPreAssessmentModal,"info":_vm.agreementCategory[0]},on:{"buy":addToCart,"close":function($event){_vm.showPreAssessmentModal = false}}}):_vm._e()],1)]}}],null,false,123604010)}):_c('div',[_c('div',{staticClass:"fluid-xl leading-tight font-semibold mb-4 text-white"},[_vm._v(_vm._s(_vm.heading))]),_vm._v(" "),(_vm.staticPrice)?_c('div',{staticClass:"fluid-xl leading-tight font-medium text-br-green-light mb-6"},[(_vm.staticCompareAtPrice)?_c('span',{staticClass:"text-gray-light inline-block line-through decoration-gray-light mr-2 fluid-xl leading-tight self-baseline font-light",attrs:{"data-test-id":"old-price"}},[_vm._v("\n            "+_vm._s(_vm.$n(_vm.staticCompareAtPrice, {
                style: 'currency',
                currency: 'EUR',
              }))+"\n          ")]):_vm._e(),_vm._v(" "),_c('span',{class:{ 'fluid-lg leading-tight font-medium': _vm.staticCompareAtPrice }},[_vm._v(_vm._s(_vm.$n(_vm.staticPrice, {
              style: 'currency',
              currency: 'EUR',
            })))]),_vm._v(" "),_c('em',{staticClass:"text-white fluid-base leading-relaxed font-light not-italic"},[_vm._v(_vm._s(_vm.$t('vatIncl')))])]):_vm._e(),_vm._v(" "),(_vm.cta[0])?_c('LinkField',_vm._b({staticClass:"btn-pill-green whitespace-nowrap mr-1.5"},'LinkField',_vm.cta[0],false)):_vm._e()],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }