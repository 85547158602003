var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.image && _vm.image[0])?_c('div',{staticClass:"relative flex flex-col",class:[
    _vm.boxOutside
      ? _vm.boxPosition === 'bottomLeft' || _vm.boxPosition === 'bottomRight'
        ? 'sm:pb-12'
        : 'sm:pt-12 sm:pb-12'
      : '',
  ]},[_c('FixedAspect',{staticClass:"order-1",attrs:{"ratio":0.55}},[(_vm.image[0])?_c('CraftAsset',{attrs:{"asset":_vm.image[0],"width":1440,"ratio":0.55,"autoplay":true,"muted":true,"hfull":true}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"sm:absolute inset-0 z-10",class:{ 'sm:py-12': !_vm.boxOutside, 'order-0': _vm.putBoxOnTopInMoble, 'order-2': !_vm.putBoxOnTopInMoble }},[_c('div',{staticClass:"sm:container sm:h-full relative flex",class:{
        'items-start': _vm.boxPosition === 'topLeft' || !_vm.boxPosition,
        'justify-end items-start': _vm.boxPosition === 'topRight',
        'items-center': _vm.boxPosition === 'middleLeft',
        'justify-end items-center': _vm.boxPosition === 'middleRight',
        'items-end': _vm.boxPosition === 'bottomLeft',
        'justify-end items-end': _vm.boxPosition === 'bottomRight',
      }},[_c('div',{staticClass:"p-6 lg:px-12 lg:pt-16 bg-blackly sm:w-80 sm:min-h-[20rem] md:w-80 md:min-h-[20rem] lg:w-[28rem] lg:min-h-[28rem] xl:w-[30rem] xl:min-h-[30rem] sm:top-1/4 flex flex-col items-start justify-center w-full",class:{ 'sm:sticky': _vm.animationEnabled }},[(_vm.tag)?_c('div',{staticClass:"text-br-green fluid-xs leading-tightest capitalize"},[_vm._v(_vm._s(_vm.tag))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"lg:mt-4 mt-2 fluid-3xl leading-tightest font-semibold text-white",attrs:{"data-test-id":"heading"},domProps:{"innerHTML":_vm._s(_vm.heading)}}),_vm._v(" "),(_vm.teaser)?_c('div',{staticClass:"text-copy fluid-base leading-relaxed font-light checkmark-bullets mt-4 text-white",domProps:{"innerHTML":_vm._s(_vm.teaser)}}):_vm._e(),_vm._v(" "),_c('LinkField',_vm._b({staticClass:"btn-pill-outline-white inline-block mt-8",attrs:{"tracking-name":_vm.heading}},'LinkField',_vm.cta[0],false))],1)])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }