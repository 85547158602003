<template>
  <div v-if="!isLoading" class="product-compare-card flex flex-row flex-wrap content-between text-center">
    <div class="w-full">
      <FixedAspect :ratio="0.68">
        <CldnImg
          v-if="imageSrc"
          :src="imageSrc"
          :alt="item.primaryAttribute"
          :ratio="0.68"
          crop="fit"
          sizes="(min-width: 1440px) 347px, (min-width: 1024px) 30vw, 100vw"
          class="variant-image"
        />
      </FixedAspect>
      <div class="mt-5 w-full">
        <h3 class="fluid-xl leading-none">{{ item.primaryAttribute }}</h3>
        <h4 v-if="item.secondaryAttribute" class="fluid-lg leading-none mt-1 text-br-green">
          {{ item.secondaryAttribute }}
        </h4>
      </div>
    </div>
    <div class="w-full text-copy mt-3 break-words" v-html="description"></div>
    <div class="w-full mt-3">
      <LinkField v-if="item.cta[0]" v-bind="item.cta[0]" class="btn-pill-outline-black" />
    </div>
  </div>
  <div v-else>
    <FixedAspect :ratio="0.55" class="md:mt-3 md:px-5 w-full max-h-full px-2 mt-6 select-none">
      <div class="animated-bg" />
      <Placeholder class="absolute inset-0" />
    </FixedAspect>
    <Placeholder v-for="index in 2" :key="index" class="w-1/2 mx-auto h-6 my-5" />
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import Placeholder from '@/components/common/Placeholder';
import LinkField from '@/components/common/LinkField';
import CldnImg from '@/components/CldnImg';

export default {
  name: 'ProductCompareCardSmall',
  components: {
    FixedAspect,
    CldnImg,
    Placeholder,
    LinkField,
  },
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    isLoading() {
      return !this.item;
    },
    description() {
      return this.item?.description;
    },
    cta() {
      return this.item?.cta?.url;
    },
    imageSrc() {
      return this.item?.image?.length && this.item?.image[0]?.url;
    },
  },
};
</script>

<style scoped>
.variant-image {
  @apply object-contain;
}
</style>
