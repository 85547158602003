<template>
  <!-- not render ssr to prevent racecondition by rendering emtpy locales before entry-query resolves and passes lodales to store  -->
  <client-only>
    <div class="bg-white">
      <div class="bg-br-green-light fluid-xs leading-tightest px-5 py-px">
        <slot name="header"></slot>
        <div v-if="navType === 'HELP' && helpNav" class="flex items-center h-12">
          {{ helpNav.navHeading }}
        </div>
        <div v-if="navType === 'ACCOUNT'" class="flex items-center h-12 space-x-3">
          <div v-if="$auth.loggedIn" class="whitespace-nowrap flex items-center justify-between min-w-[180px] w-full">
            <span class="mr-6">{{ $t('account.welcome', { name: currentUserName }) }}</span>
            <button class="hover:no-underline text-blackly-dark hover:text-black underline" @click="handleLogout">
              {{ $t('account.logout') }}
            </button>
          </div>
          <template v-else>
            <nuxt-link
              :to="$localeRootPath('/account/login')"
              data-testid="btn-account-login"
              class="font-medium underline whitespace-nowrap"
              @click.native="handleLoginClick"
            >
              {{ $t('navigation.login') }}</nuxt-link
            >
            <nuxt-link
              :to="$localeRootPath('/account/register')"
              data-testid="btn-account-register"
              class="font-medium underline whitespace-nowrap"
              @click.native="handleRegisterClick"
            >
              {{ $t('navigation.register') }}</nuxt-link
            >
          </template>
        </div>
      </div>
      <ul v-if="links && links.length > 0" class="py-2">
        <li v-for="(link, i) in links" :key="i">
          <LinkField
            class="fluid-sm tracking-wider hover:bg-putty whitespace-nowrap block px-5 py-3 font-normal text-black no-underline transition-all"
            :class="{ uppercase: navType === 'LOCALES' }"
            v-bind="link.linkField"
            @click.native="$emit('onClick') && link.onClickHandler && link.onClickHandler()"
          >
            <Icon v-if="link.icon" class="text-br-green inline-block w-5 h-5 mr-2 fill-current" :name="link.icon" />
            <span v-if="link.label">{{ $t(link.label) }}</span>
          </LinkField>
        </li>
      </ul>

      <div v-if="navType === 'ACCOUNT' && !$auth.user" class="px-5 py-2">
        <ul v-if="benefits && benefits.length > 0" class="whitespace-nowrap fluid-sm py-4 space-y-4 font-light">
          <li v-for="item in benefits" :key="item.id">
            <CheckmarkIcon class="text-br-green h-3 mr-1" /> {{ item.description }}
          </li>
        </ul>
      </div>
    </div>
  </client-only>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import LinkField from '@/components/common/LinkField';
import CheckmarkIcon from '@/components/icons/CheckmarkIcon';
import Icon from '@/components/icons/Icon';
import gql from 'graphql-tag';

export const AccountBenefitsFragment = gql`
  fragment AccountBenefitsFragment on account_GlobalSet {
    id
    name
    benefitsBlocks {
      ... on oneliner_Entry {
        id
        description
        typeHandle
      }
    }
  }
`;

export default {
  components: { LinkField, CheckmarkIcon, Icon },

  props: {
    navType: {
      type: String,
      default: null,
      validator: (value) => ['LOCALES', 'HELP', 'ACCOUNT'].includes(value),
    },
  },

  computed: {
    ...mapState('mainNav', ['helpNav', 'benefits']),
    ...mapGetters('mainNav', ['alternates']),
    currentUserName() {
      return this.$auth.user?.blackrollCustomer?.displayName ?? this.$auth.user?.displayName;
    },
    links() {
      const { locales } = this.$i18n;
      switch (this.navType) {
        case 'ACCOUNT':
          if (this.$auth.user) {
            const items = [
              {
                label: 'navigation.myProfile',
                icon: 'account',
                linkField: {
                  to: this.$localeRootPath('/account/profile/details'),
                },
              },
              {
                label: 'navigation.myOrders',
                icon: 'product',
                linkField: {
                  to: this.$localeRootPath('/account/orders'),
                },
              },
              {
                label: 'navigation.myExercises',
                icon: 'exercise',
                linkField: {
                  to: this.$localeRootPath('/account/exercises/'),
                },
              },
            ];

            // add navigation element only if on DE page
            if (this.$i18n.locale === 'de') {
              items.push({
                label: 'navigation.myCourses',
                icon: 'academy',
                linkField: {
                  to: this.$localeRootPath('/account/courses'),
                },
              });
            }

            return items;
          }

          return [];

        case 'HELP':
          return this.helpNav ? this.helpNav.links : null;
        case 'LOCALES':
          return locales.reduce((acc, localeConfig) => {
            const { code } = localeConfig;
            if (this.alternates[code] && code !== 'ch-de' && code !== 'ch-fr')
              acc.push({
                linkField: { text: code, url: this.alternates[code], type: 'url' },
              });
            return acc;
          }, []);
        default:
          return [];
      }
    },
  },
  methods: {
    handleLoginClick() {
      this.$emit('onClick');
      const clickData = {
        event: 'gaEvent',
        event_name: 'login_click',
        login_click: {
          event_category: 'Account',
          event_action: 'Login',
          event_label: 'Click',
        },
      };
      this.$_gtm.push(clickData);
    },
    handleRegisterClick() {
      this.$emit('onClick');
      const clickData = {
        event: 'gaEvent',
        event_name: 'sign_up_click',
        sign_up_click: {
          event_category: 'Account',
          event_action: 'Sign Up',
          event_label: 'Click',
        },
      };
      this.$_gtm.push(clickData);
    },
    handleLogout() {
      this.$auth.logout().then(() => {
        const eventData = {
          event: 'gaEvent',
          event_name: 'logout',
          logout: {
            event_category: 'Account',
            event_action: 'Logout',
            event_label: 'Success',
          },
        };
        this.$_gtm.push(eventData);
      });
    },
  },
};
</script>
