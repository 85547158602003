<template>
  <div ref="trigger" class="sm:pb-16 relative pr-8">
    <FixedAspect :ratio="5 / 4">
      <CldnImg
        v-if="topic.image[0]"
        :src="topic.image[0].url"
        :alt="topic.image[0].title"
        sizes="(min-width: 1440px) 548px,(min-width: 640px)40vw, 100vw"
        :ratio="5 / 4"
        crop="fill"
        class="max-w-none absolute inset-0 z-0 block object-cover w-full h-full"
      />
    </FixedAspect>

    <div class="-mt-12 lg:-mt-24 relative">
      <ParallaxBox :factor="50" :start-breakpoint="1024" class="box-thin ml-4 -mr-4 lg:ml-12 lg:-mr-12 z-10">
        <div v-if="entryCategories">
          <TagBtn
            v-for="category in entryCategories"
            :key="category.id"
            :to="{
              name: `knowledge-index-category-category___${$i18n.locale}`,
              params: { category: category.slug },
            }"
            secondary
            class="mr-2"
          >
            {{ category.title }}
          </TagBtn>
        </div>
        <h3 class="fluid-2xl leading-tightest">{{ topic.heading }}</h3>
        <div class="mt-4 fluid-base font-light leading-normal"><div class="line-clamp-3" v-html="topic.body" /></div>
        <nuxt-link
          v-if="topic.cta[0]"
          class="btn-pill-black inline-block mt-4"
          :to="$localeRootPath(topic.cta[0].element?.uri)"
        >
          {{ topic.cta[0].text || topic.cta[0].element.title }}</nuxt-link
        >
      </ParallaxBox>
    </div>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import ParallaxBox from '@/components/common/ParallaxBox';
import TagBtn from '@/components/common/TagBtn';

export default {
  components: {
    FixedAspect,
    CldnImg,
    ParallaxBox,
    TagBtn,
  },
  props: {
    topic: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    entryCategories() {
      return this.topic?.entry[0]?.categories;
    },
  },
};
</script>
