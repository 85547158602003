<template>
  <div v-if="socialMediaAccounts">
    <div class="fluid-xs leading-snug uppercase font-medium mb-4">{{ $t('navigation.followUs') }}</div>
    <InlineIconsGrid class="-ml-2 -mr-2">
      <div v-for="(account, idx) in socialMediaAccounts.socialMediaAccounts" :key="idx">
        <a :href="account.accountUrl" target="_blank" class="flex p-2 hover:opacity-60 transition-all w-9">
          <FacebookIcon v-if="account.network === 'facebook'" class="h-5" />
          <InstagramIcon v-if="account.network === 'instagram'" class="h-5" />
          <YoutubeIcon v-if="account.network === 'youtube'" class="h-5" />
          <LinkedinIcon v-if="account.network === 'linkedin'" class="h-5" />
        </a>
      </div>
    </InlineIconsGrid>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import FacebookIcon from '@/components/icons/FacebookIcon';
import InstagramIcon from '@/components/icons/InstagramIcon';
import YoutubeIcon from '@/components/icons/YoutubeIcon';
import InlineIconsGrid from '@/components/common/InlineIconsGrid';
import LinkedinIcon from '@/components/icons/LinkedinIcon';

export const SocialMediaAccountsFragment = gql`
  fragment SocialMediaAccountsFragment on socialMediaAccounts_GlobalSet {
    socialMediaAccounts {
      ... on socialMediaAccountsBlock_Entry {
        network
        accountUrl
      }
    }
  }
`;

export default {
  components: { LinkedinIcon, InlineIconsGrid, YoutubeIcon, InstagramIcon, FacebookIcon },
  props: {
    socialMediaAccounts: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
