<script>
import { stripHtml } from '@/utils/common';

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    element: {
      type: Object,
      default: () => ({}),
    },
    text: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    activeClass: {
      type: String,
      default: null,
    },
    exactActiveClass: {
      type: String,
      default: null,
    },
    to: {
      type: [Object, String],
      default: () => null,
    },
    blockPosition: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    typeName: {
      type: String,
      default: null,
    },
    trackingName: {
      type: String,
      default: null,
    },
    noTracking: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    gtmData() {
      if (this.noTracking) return;

      const typeHandle = this.$parent?.typeHandle ?? this.$parent?.$attrs.typeHandle ?? this.typeName;
      const promotionName =
        this.trackingName && typeHandle
          ? [typeHandle, stripHtml(this.trackingName)].join('_').toLowerCase()
          : stripHtml(this.trackingName ?? typeHandle ?? false);

      if (!promotionName) return false;

      const blockPosition = this.blockPosition ?? this.$parent?.blockPosition ?? 0;

      return {
        promotion_id: promotionName.toLowerCase(),
        promotion_name: [this.$route?.name, promotionName].join(' / '),
        creative_name: typeHandle,
        creative_slot: [this.$route?.name, blockPosition, this.index].join(' / '),
      };
    },
    handleClick() {
      if (this.element?.uri?.startsWith('exercises')) {
        const exerciseData = {
          event: 'gaEvent',
          event_name: 'exercise_view',
          exercise_view: {
            exercise_name: this.text,
            // topic: '{SEO Topic}',
            event_category: 'Exercise',
            event_action: 'View',
            event_label: this.text,
          },
        };
        this.$_gtm.push(exerciseData);
      }
    },
  },

  render(h) {
    const slots = !this.$slots.default && this.text ? [this.text] : this.$slots.default;

    const directives =
      this.noTracking || this.gtmData() === false
        ? []
        : [
            {
              name: 'track',
              arg: 'click',
              value: this.gtmData(),
              modifiers: {
                track: true,
              },
            },
            {
              name: 'track',
              arg: 'view',
              value: this.gtmData(),
            },
          ];

    if (this.target !== null && this.target.length > 0) {
      const domProps = { href: this.url, target: this.target };
      return h(
        'a',
        {
          domProps,
          directives,
        },
        slots
      );
    }

    if (this.to !== null) {
      return h(
        'nuxt-link',
        {
          props: { to: this.to, activeClass: this.activeClass, exactActiveClass: this.exactActiveClass },
          directives,
        },
        slots
      );
    }

    if (!this.type) {
      return null;
    }

    if (this.type.includes('Entry') && this.element) {
      return h(
        'nuxt-link',
        {
          props: {
            to: this.$localeRootPath(this.element.uri),
            activeClass: this.activeClass,
            exactActiveClass: this.exactActiveClass,
          },
          nativeOn: {
            click: this.handleClick,
          },
          directives,
        },
        slots
      );
    }

    if (this.type.includes('Url') || this.type.includes('Email')) {
      const domProps = { href: this.url };
      return h('a', { domProps, directives }, slots);
    }
    return null;
  },
};
</script>
