<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="block"
        :products-connection="block.typeHandle === 'productGrid' && collection ? collection.products : null"
        :grid-blocks="gridBlocks"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import guestComponent from '@/mixins/guestComponent';

import ProductListingFeature, {
  ShopBlocksProductListingFeatureFragment,
} from '@/components/common/blocks/ProductListingFeature';
import AsSeenIn, { MarketingBlocksAsSeenInFragment } from '@/components/common/blocks/AsSeenIn';
import ImageTitleFeature, { ShopBlocksImageTitleFeatureFragment } from '@/components/common/blocks/ImageTitleFeature';
import ProductSlider, { MarketingBlocksProductSliderFragment } from '@/components/common/blocks/ProductSlider';
import LargeImageFeature, { ShopBlocksLargeImageFeatureFragment } from '@/components/common/blocks/LargeImageFeature';
import CollectionsSlider, {
  MarketingBlocksCollectionsSliderFragment,
} from '@/components/common/blocks/CollectionsSlider';
import FullWidthTeaserBox, {
  MarketingBlocksFullWidthTeaserBoxFragment,
} from '@/components/common/blocks/FullWidthTeaserBox';
import ProductFeatureThree, {
  ShopBlocksProductFeatureThreeFragment,
} from '@/components/common/blocks/ProductFeatureThree';
import SmallTopicFeature, { SmallTopicFeatureFragment } from '@/components/common/blocks/SmallTopicFeature/index';
import AppCta, { MarketingBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import ProductGrid, { ShopBlocksProductGridFragment } from '@/components/common/blocks/ProductGrid';
import ExerciseRoutineCardsSliderWithText, {
  MarketingBlocksRoutineCardsSliderWithTextFragment,
} from '@/components/common/blocks/ExerciseRoutineCardsSliderWithText';
import ContentRecommendationSlide, {
  StoriesPageBlocksContentRecommendationSlideFragment,
} from '@/components/common/blocks/ContentRecommendationSlide';
import CollectionTitle, { ShopBlocksCollectionTitleFragment } from '@/components/common/blocks/CollectionTitle';
import CollectionInformation, {
  ShopBlocksCollectionInformationFragment,
} from '@/components/common/blocks/CollectionInformation';
import CenteredContent from '@/components/common/blocks/CenteredContent';
import { MarketingBlocksCenteredContentFragment } from '@/components/common/blocks/CenteredContent/index';
import ImageText, { MarketingBlocksImageTextFragment } from '~/components/common/blocks/ImageText';
import CompareContentSlider, {
  ShopBlocksCompareContentSliderFragment,
} from '@/components/common/blocks/CompareContentSlider';
import CompareTable, { ShopBlocksCompareTableFragment } from '~/components/common/blocks/CompareTable';
import GenericIframe, { MarketingBlocksGenericIframeFragment } from '@/components/common/blocks/GenericIframe';
import ImageOrVideo from '@/components/common/blocks/ImageOrVideo';
import { MarketingBlocksImageOrVideoFragment } from '@/components/common/blocks/ImageOrVideo/index';
import NewsletterSignupForm, {
  MarketingBlocksNewsletterSignupFormFragment,
} from '@/components/common/blocks/NewsletterSignupForm';

export const ShopBlocksMatrixFragment = gql`
  fragment ShopBlocksMatrixFragment on shopBlocks_MatrixField {
    ...ShopBlocksProductListingFeatureFragment
    ...MarketingBlocksAsSeenInFragment
    ...ShopBlocksImageTitleFeatureFragment
    ...MarketingBlocksProductSliderFragment
    ...ShopBlocksLargeImageFeatureFragment
    ...MarketingBlocksCollectionsSliderFragment
    ...MarketingBlocksFullWidthTeaserBoxFragment
    ...ShopBlocksProductFeatureThreeFragment
    ...SmallTopicFeatureFragment
    ...MarketingBlocksAppCtaFragment
    ...ShopBlocksProductGridFragment
    ...MarketingBlocksRoutineCardsSliderWithTextFragment
    ...StoriesPageBlocksContentRecommendationSlideFragment
    ...ShopBlocksCollectionTitleFragment
    ...ShopBlocksCollectionInformationFragment
    ...MarketingBlocksCenteredContentFragment
    ...MarketingBlocksImageTextFragment
    ...ShopBlocksCompareContentSliderFragment
    ...ShopBlocksCompareTableFragment
    ...MarketingBlocksGenericIframeFragment
    ...MarketingBlocksImageOrVideoFragment
    ...MarketingBlocksNewsletterSignupFormFragment
  }
  ${ShopBlocksProductListingFeatureFragment}
  ${MarketingBlocksAsSeenInFragment}
  ${ShopBlocksImageTitleFeatureFragment}
  ${MarketingBlocksProductSliderFragment}
  ${ShopBlocksLargeImageFeatureFragment}
  ${MarketingBlocksCollectionsSliderFragment}
  ${MarketingBlocksFullWidthTeaserBoxFragment}
  ${ShopBlocksProductFeatureThreeFragment}
  ${SmallTopicFeatureFragment}
  ${MarketingBlocksAppCtaFragment}
  ${ShopBlocksProductGridFragment}
  ${MarketingBlocksRoutineCardsSliderWithTextFragment}
  ${StoriesPageBlocksContentRecommendationSlideFragment}
  ${ShopBlocksCollectionTitleFragment}
  ${ShopBlocksCollectionInformationFragment}
  ${MarketingBlocksCenteredContentFragment}
  ${MarketingBlocksImageTextFragment}
  ${ShopBlocksCompareContentSliderFragment}
  ${ShopBlocksCompareTableFragment}
  ${MarketingBlocksGenericIframeFragment}
  ${MarketingBlocksImageOrVideoFragment}
  ${MarketingBlocksNewsletterSignupFormFragment}
`;

export default {
  name: 'ShopBlocks',
  components: {
    GenericIframe,
    CompareContentSlider,
    ImageText,
    CenteredContent,
    FullWidthTeaserBox,
    ProductListingFeature,
    AsSeenIn,
    ImageTitleFeature,
    ProductSlider,
    LargeImageFeature,
    CollectionsSlider,
    ProductFeatureThree,
    SmallTopicFeature,
    AppCta,
    ProductGrid,
    ExerciseRoutineCardsSliderWithText,
    ContentRecommendationSlide,
    CollectionTitle,
    CollectionInformation,
    CompareTable,
    ImageOrVideo,
    NewsletterSignupForm,
  },
  mixins: [guestComponent],
  props: {
    blocks: {
      type: Array,
      default: () => [],
    },
    gridBlocks: {
      type: Array,
      default: () => [],
    },
    collection: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
