<template>
  <ul>
    <li v-for="(item, index) in links" :key="index" class="text-blackly">
      <LinkField
        v-bind="item.link[0]"
        class="py-2 inline-block hover:opacity-60 transition-opacity"
        :active-class="'text-br-green'"
        :class="{
          'fluid-sm tracking-wider font-medium': index === 0,
          'fluid-xs leading-tightest': index !== 0,
          'text-br-red': item.highlight,
        }"
        no-tracking
        @click.native="handleNavClick(index)"
      >
        {{ item.link[0].text }}
        <sup v-if="item.new" class="text-br-green fluid-xxs uppercase">
          {{ $t('navigation.new') }}
        </sup>
      </LinkField>
    </li>
  </ul>
</template>

<script>
import gql from 'graphql-tag';
import LinkField from '@/components/common/LinkField';

export const NavBlocksLinkSectionFragment = gql`
  fragment NavBlocksLinkSectionFragment on linkSection_Entry {
    typeHandle
    startNewColumn
    links: navLinkSectionLinks {
      ... on navLinkSectionLinksBlock_Entry {
        link: linkField {
          ...LinkFragment
        }
        mobileTitle
        new
        highlight
        image {
          url
          title
        }
      }
    }
  }
`;

export default {
  components: { LinkField },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    startNewColumn: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleNavClick(index) {
      const eventLabel = index === 0 ? 'first level navigation' : 'second level navigation';

      const data = {
        event: 'gaEvent',
        event_name: 'navigation',
        navigation: {
          navigation_area: 'Header',
          navigation_item: eventLabel,
          event_category: 'Navigation',
          event_action: 'Header',
          event_label: eventLabel,
        },
      };
      this.$_gtm.push(data);
      this.$store.dispatch('mainNav/setOpenNavTopIndex');
    },
  },
};
</script>
