<template>
  <div :class="[backgroundBlack ? 'bg-blackly py-20' : 'my-20']">
    <div class="container text-center">
      <div
        v-if="caption"
        class="text-br-green font-semibold fluid-xs leading-tightest mb-4"
        data-testid="caption"
        v-html="caption"
      ></div>
      <h2
        class="fluid-4xl leading-none max-w-xl mx-auto mb-10"
        :class="{ 'text-br-green-light': backgroundBlack }"
        v-html="heading"
      ></h2>
      <div
        v-if="body"
        class="text-copy fluid-base leading-relaxed font-light max-w-3xl mx-auto"
        :class="{ 'text-white': backgroundBlack }"
        data-testid="body"
        v-html="body"
      ></div>
      <div v-if="cta[0] && cta[0].url" class="mt-10">
        <LinkField
          v-bind="cta[0]"
          class="btn-pill-outline-black"
          :class="{ 'btn-pill-outline-white': backgroundBlack }"
        />
      </div>
      <div class="flex flex-row justify-center sm:justify-evenly flex-wrap">
        <div v-for="icon in icons" :key="icon.id" class="flex flex-col items-center mt-14 w-1/2 sm:w-auto">
          <CldnImg
            v-if="iconSrc(icon)"
            :src="iconSrc(icon)"
            height="32"
            crop="fit"
            data-test-id="icon"
            :alt="icon.heading"
            class="h-8"
          />
          <span
            class="font-medium fluid-xs leading-tightest mt-6"
            :class="{ 'text-white': backgroundBlack }"
            v-html="icon.heading"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkField from '~/components/common/LinkField';
import CldnImg from '~/components/CldnImg';

export default {
  components: { CldnImg, LinkField },
  props: {
    caption: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    backgroundBlack: {
      type: Boolean,
      default: false,
    },
    cta: {
      type: Array,
      default: () => [],
    },
    icons: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    iconSrc(icon) {
      return icon.icon.length && icon.icon[0].url;
    },
  },
};
</script>
