<template>
  <div class="2xl:container mt-20 mb-32 relative flex flex-col">
    <CardSliderNav
      v-if="slides.length > 1"
      ref="nav"
      class="absolute top-0 right-4 sm:right-16 lg:right-0 mt-8 sm:mr-8 z-20"
      @prev="() => $refs.slider.$emit('prev')"
      @next="() => $refs.slider.$emit('next')"
    />
    <FixedAspect :ratio="1" class="w-full md:w-1/2 lg:-mt-10 z-10">
      <CardSlider
        v-if="slides && slides.length"
        ref="slider"
        :slide-gap="0"
        :show-arrows="false"
        :fullwidth-slides="true"
        :is-container="false"
        :breakpoints="breakpoints"
        @change="(innerState) => setActiveSlide(innerState)"
      >
        <div v-for="slide in slides" :key="slide.id" class="article-wrapper relative">
          <CldnImg
            v-if="slide.overlayImage.length"
            :src="slide.overlayImage[0].url"
            sizes="(min-width: 1440px) 324px, (min-width: 1024px) 25vw, 50vw"
            crop="fill"
            class="max-w-none h-full w-full absolute top-0 left-0 z-10 block object-cover"
            :alt="slide.overlayImage[0].title"
          />
          <FixedAspect :ratio="1" class="w-full">
            <CraftAsset
              v-if="slide.image.length"
              :alt="slide.alt"
              :asset="slide.image[0]"
              sizes="(min-width: 1440px) 648px, (min-width: 1024px) 50vw, 100vw"
              :ratio="1"
              crop="fill"
              class="max-w-none absolute inset-0 z-0 block object-cover w-full h-full"
              :autoplay="true"
            />
          </FixedAspect>
        </div>
      </CardSlider>
    </FixedAspect>
    <div
      class="md:items-end md:flex md:flex-row md:absolute md:inset-0 md:justify-end mt-4 md:mt-20 pointer-events-none"
      :style="styleHeight"
    >
      <div
        class="md:pr-10 md:py-20 md:w-7/12 md:pl-40 md:h-full z-0 p-8 pb-12"
        :class="[
          {
            'bg-blackly text-white': !appearance || appearance === 'black',
            'bg-br-green-light text-blackly': appearance === 'lightGreen',
          },
        ]"
      >
        <transition name="fade" mode="out-in" :duration="150">
          <!-- eslint-disable vue/no-use-v-if-with-v-for-->
          <div
            v-for="(slide, index) in slides"
            v-if="index === currentSlide"
            :key="slide.id"
            class="inner h-full pointer-events-auto"
          >
            <div ref="boxes" class="black-box flex flex-wrap flex-initial h-full">
              <div class="w-full mb-4 fluid-xl leading-tight" v-html="slide.heading"></div>
              <div class="text-copy lg:mb-0 w-full mb-4 fluid-base checkmark-bullets" v-html="slide.body"></div>
              <FixedAspect :ratio="2 / 10" class="self-end w-full">
                <CldnImg
                  v-if="slide.productAttributes && slide.productAttributes.length"
                  :src="slide.productAttributes[0] && slide.productAttributes[0].url"
                  :ratio="2 / 10"
                  crop="fill"
                  sizes="(min-width: 1440px) 556px, (min-width: 1024px) 50vw, 100vw"
                  class="max-w-none absolute inset-0 z-0 block object-cover w-full h-full"
                  :alt="slide.alt"
                />
              </FixedAspect>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import FixedAspect from '~/components/common/FixedAspect';
import CldnImg from '~/components/CldnImg';
import CraftAsset from '~/components/common/CraftAsset';
import CardSlider from '~/components/common/CardSlider';
import CardSliderNav from '~/components/common/CardSliderNav';

export default {
  name: 'SplitContentSlider',
  components: {
    CardSliderNav,
    CardSlider,
    CraftAsset,
    FixedAspect,
    CldnImg,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
    appearance: {
      type: String,
      default: 'black',
      validator: (value) => ['black', 'lightGreen'].includes(value),
    },
  },
  data() {
    return {
      currentSlide: 0,
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      ],
      boxMinHeight: 0,
    };
  },
  computed: {
    styleHeight() {
      return {
        'min-height': this.boxMinHeight + 'px',
      };
    },
  },
  mounted() {
    this.adjustSlidesHeight();
    window.addEventListener('resize', this.adjustSlidesHeight);
  },
  methods: {
    setActiveSlide(state) {
      if (typeof state !== 'object' && !state) return;
      this.$refs.nav?.$emit('sliderChange', state);
      this.currentSlide = state?.currentSlide;
    },
    adjustSlidesHeight() {
      this.boxMinHeight = this.$refs.slider?.$el.getBoundingClientRect().height;
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity delay-300;
}
.fade-enter,
.fade-leave-active {
  @apply opacity-0;
}
</style>
