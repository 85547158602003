<template>
  <div class="container flex flex-wrap my-20">
    <div v-if="heading || cta[0]" class="flex flex-wrap my-8 w-full">
      <h4 v-if="heading" class="w-full md:w-1/2">{{ heading }}</h4>
      <div v-if="cta[0]" class="w-full md:w-1/2 text-right hidden md:flex flex-col-reverse pr-8">
        <CtaArrow v-bind="cta[0]" class="cta-arrow" data-test-id="cta" />
      </div>
    </div>
    <div class="flex flex-col sm:flex-row sm:space-x-16 w-full space-y-16 sm:space-y-0">
      <TopicFeature
        v-for="(topic, index) in topics"
        :key="topic.id"
        v-track:view="gtmData(topic, index)"
        v-track:click="gtmData(topic, index)"
        :topic="topic"
        class="w-full sm:w-1/2"
        data-test-id="topic"
      />
    </div>
  </div>
</template>

<script>
import TopicFeature from '@/components/knowledge/topic/TopicFeature';
import CtaArrow from '@/components/common/CtaArrow';

export default {
  components: {
    TopicFeature,
    CtaArrow,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
    topics: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.cta[0]?.element?.uri,
        promotion_name: [this.$route?.name, payload.heading].join(' / '),
        creative_name: 'smallTopicFeatureBox',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>
