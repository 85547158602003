<template>
  <div class="w-full shadow-md" :class="[cartDealSettings.useAlternativeStyle ? 'bg-putty' : 'bg-white']">
    <div class="fluid-sm leading-tight bg-br-green-light text-black py-1 text-center">
      <TagIcon v-if="cartDealSettings.showIcon" class="w-3 h-3 inline-block mr-1 text-black" />
      <span class="font-medium">{{ $t('cart.cartDealsHeading') }}</span>
    </div>

    <div class="flex items-center w-full py-2 px-4">
      <div class="w-full max-w-[82px] sm:max-w-[112px] relative mt-2">
        <FixedAspect v-if="image" :ratio="2 / 3">
          <CldnImg :src="image" :alt="title" :quality="90" crop="fit" :ratio="2 / 3" width="100" />
        </FixedAspect>

        <div v-if="discountAnnotation" class="absolute bg-br-red fluid-xs py-px px-3 top-0 left-0 text-white font-bold">
          - {{ discountAnnotation }}
        </div>
      </div>

      <div class="flex flex-no-wrap items-center justify-between py-2 w-full ml-2 space-x-2">
        <div class="fluid-sm leading-tightest w-full">
          <div class="font-medium overflow-hidden">
            {{ title }}
          </div>
          <div v-if="variant" class="text-gray-light mt-0.5">
            {{ variant.title }}
          </div>
        </div>

        <div class="text-right pr-2">
          <ProductPriceLine
            v-if="calculatedPrice.amount > 0"
            v-bind="{
              price: calculatedPrice,
              showVatHint: false,
              showCentered: false,
              showQuantity: true,
              unitPrice,
              unitPriceMeasurement,
              size: 'xs',
            }"
          />
          <span v-else class="text-br-green text-xs font-medium uppercase">{{ $t('For free') }}</span>
        </div>

        <CtaButton
          class="p-3 shrink-0 rounded-full min-w-0"
          data-testid="smBtnAddToCart"
          :loading="isLoading"
          @click.native="addDealToCart"
        >
          <PlusIcon class="h-4 w-4" />
        </CtaButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import ProductPriceLine from '@/components/shop/products/ProductPriceLine';
import PlusIcon from '@/components/icons/PlusIcon';
import CtaButton from '@/components/common/CtaButton';
import TagIcon from '@/components/icons/TagIcon';

export default {
  components: { FixedAspect, CldnImg, ProductPriceLine, PlusIcon, CtaButton, TagIcon },

  props: {
    deal: { type: Object, required: true },

    title: { type: String, default: null },
    description: { type: String, default: null },
    image: { type: String, default: null },
    price: { type: Object, default: () => null },
    discountPerProduct: { type: [Number, Boolean], default: false },
    unitPrice: { type: Object, default: () => {} },
    unitPriceMeasurement: { type: Object, default: () => {} },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('cart', ['lineItems']),
    ...mapState('cartSettings', ['cartDealSettings']),
    priceWithoutDiscount() {
      return this.deal.products.map((product) => product.variants[0].price.amount).reduce((a, b) => a + b, 0);
    },
    discountAnnotation() {
      if (!this.deal.discountValue) {
        return false;
      }

      const formatter = Intl.NumberFormat(this.$locale, {
        style: 'currency',
        currency: this.price.currencyCode,
      });

      if (this.deal.discountType === 'percentage') {
        return `${this.deal.discountValue}%`;
      } else if (this.deal.discountType === 'fixedAmount') {
        const discount = formatter.format(this.deal.discountValue);
        return `${discount}`;
      } else if (this.deal.discountType === 'fixedTargetPrice') {
        const discount = formatter.format(
          Math.round((this.priceWithoutDiscount - this.deal.discountValue) * 100) / 100
        );

        return discount;
      } else {
        return false;
      }
    },

    variant() {
      try {
        return this.deal?.focusProduct?.variants[0];
      } catch (e) {
        return null;
      }
    },

    calculatedPrice() {
      if (this.discountPerProduct) {
        return {
          amount: Math.round((this.price.amount - (this.price.amount * this.discountPerProduct) / 100) * 100) / 100,
          currencyCode: this.price.currencyCode,
        };
      }

      return this.price;
    },

    href() {
      return (
        this.variant?.product?.handle &&
        this.variant?.sku &&
        `/products/${this.variant.product.handle}?sku=${this.variant.sku}`
      );
    },
  },

  methods: {
    ...mapActions('cart', ['close']),

    async addDealToCart() {
      try {
        this.isLoading = true;
        let pos = 0;

        const addonProducts = this.deal.discountProducts
          .filter((product) => {
            return this.lineItems.map((l) => l.variant.sku).includes(product.variants[0].sku) === false;
          })
          .map((product) => {
            pos += 1;

            return {
              id: product.variants[0].id,
              position: pos,
            };
          });

        const products = this.deal.products
          .filter((product) => {
            return this.lineItems.map((l) => l.variant.sku).includes(product.variants[0].sku) === false;
          })
          .map((product) => {
            pos += 1;

            return {
              id: product.variants[0].id,
              position: pos,
            };
          });

        await this.deal.pickystory.addVariantsToCart(products, 1, this.deal.isBogo ? addonProducts : null);
      } catch (error) {
        this.$sentry.captureException(error);
      } finally {
        await this.$store.dispatch('cart/refreshCheckout');
        this.isLoading = false;
      }
    },
  },
};
</script>
