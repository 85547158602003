<template>
  <CardSlider v-if="teasers.length" ref="teaserSlider" :breakpoints="breakpoints" :show-arrows="false" class="my-20">
    <div
      v-for="(teaser, index) in teasers"
      :key="index"
      v-track:view="gtmData(teaser, index)"
      data-test-id="teaser-carousel"
      class="flex flex-col md:flex-row w-full"
    >
      <FixedAspect :ratio="4 / 5" class="md:w-1/2 w-full">
        <CldnImg
          v-if="teaser.image[0]"
          :src="teaser.image[0].url"
          sizes="(min-width: 1440px) 648px,(min-width: 768px) 45vw, 90vw"
          crop="fill"
          :alt="teaser.alt ? teaser.alt : teaser.image[0].title"
          :ratio="4 / 5"
        />
      </FixedAspect>
      <div class="md:w-1/2 bg-blackly p-6 md:p-12 text-white flex flex-col justify-between w-full h-full">
        <ArrowBtnNav
          v-if="teasers.length > 1"
          @onLeftClick="$refs.teaserSlider.$emit('prev')"
          @onRightClick="$refs.teaserSlider.$emit('next')"
        />
        <h2 class="my-4 fluid-3xl leading-tightest" v-html="teaser.heading"></h2>
        <div class="fluid-base leading-relaxed font-light" v-html="teaser.body"></div>
        <div class="md:flex md:flex-row md:justify-between md:flex-wrap grid grid-cols-2 mt-4 h-full">
          <div v-for="({ articleLink }, i) in teaser.articleLinks" :key="i" class="md:my-0 my-2">
            <CtaArrow
              v-track:click="gtmData(teaser, index, articleLink)"
              v-bind="articleLink[0]"
              class="text-cta-arrow font-medium"
            >
              {{ articleLink[0].text || articleLink[0].element.title }}
            </CtaArrow>
          </div>
        </div>
      </div>
    </div>
  </CardSlider>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import CtaArrow from '@/components/common/CtaArrow';
import ArrowBtnNav from '@/components/common/ArrowBtnNav';
import CardSlider from '@/components/common/CardSlider';
import { stripHtml } from '@/utils/common';

export default {
  components: { CardSlider, ArrowBtnNav, FixedAspect, CtaArrow, CldnImg },
  props: {
    teasers: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 640,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 768,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      ],
    };
  },

  methods: {
    gtmData(payload, index, link = null) {
      const promotion = stripHtml(payload.heading);

      return {
        promotion_id: promotion?.toLowerCase().replace(/ /g, '-'),
        promotion_name: promotion,
        creative_name: 'teaserWithMultipleCtas',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1, link?.text].join(' / '),
      };
    },
  },
};
</script>
