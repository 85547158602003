<template>
  <div class="box flex flex-col justify-between">
    <BarText v-if="heading" tag="h2" class="mb-4 md:m-0" :text="heading" data-test-id="header" />
    <p v-if="body" class="fluid-lg font-light leading-normal mb-4 md:m-0">{{ body }}</p>
    <CtaArrow v-if="cta[0]" v-bind="cta[0]" class="cta-arrow font-medium" />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import CtaArrow from '@/components/common/CtaArrow';
import BarText from '@/components/BarText';

export const GridBlocksOneByOneBoxFragment = gql`
  fragment GridBlocksOneByOneBoxFragment on oneByOneBox_Entry {
    typeHandle
    position
    heading
    body
    cta {
      ...LinkFragment
    }
  }
`;
export default {
  components: { CtaArrow, BarText },
  cols: 1,
  rows: 1,
  props: {
    position: {
      type: Number,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
