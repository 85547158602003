<template>
  <div class="flex flex-row relative bg-blackly">
    <CraftAsset
      v-if="image[0]"
      class="h-full object-cover hidden md:block w-full"
      :ratio="2 / 3"
      crop="fill"
      sizes="(min-width: 1440px) 851px, 60vw"
      :asset="image[0]"
      :alt="alt"
      autoplay
      muted
    />
    <div class="text-white md:absolute h-full w-full flex flex-col justify-between">
      <BarText v-if="heading" tag="h2" class="p-6 pb-0 md:p-12" :text="heading" data-test-id="header" />
      <CraftAsset
        v-if="image[0]"
        class="h-full w-full block md:hidden right-0"
        :ratio="2 / 3"
        crop="fill"
        sizes="100vw"
        :asset="image[0]"
        :alt="alt"
        autoplay
        muted
      />
      <CtaArrow v-if="cta[0]" v-bind="cta[0]" class="cta-arrow font-medium p-6 pt-0 md:p-12" />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import CtaArrow from '@/components/common/CtaArrow';
import CraftAsset from '@/components/common/CraftAsset';
import BarText from '@/components/BarText';
export const GridBlocksTwoByOneImageCtaFragment = gql`
  fragment GridBlocksTwoByOneImageCtaFragment on twoByOneImageCta_Entry {
    typeHandle
    position
    image {
      ...CraftAssetFragment
    }
    alt
    heading
    cta {
      ...LinkFragment
    }
  }
`;
export default {
  components: { CtaArrow, CraftAsset, BarText },
  cols: 2,
  rows: 1,
  props: {
    position: {
      type: Number,
      default: null,
    },
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      default: null,
    },
    cta: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
